import config from '../../../config'
import store from '../../store'
import { order_list_by } from '../../util/utils'

export default {
  namespaced: true,
  state: {
    tipos_cuenta: [],
    tipos_movimiento: [],
    all_cuentas: [],
    all_bancos: [],
    bancos: []
  },
  mutations: {
    set_tipos_cuenta (state, payload) {
      state.tipos_cuenta = payload
    },
    set_tipos_movimiento (state, payload) {
      order_list_by(payload, 'nombre')
      state.tipos_movimiento = payload
    },
    set_all_cuentas (state, payload) {
      state.all_cuentas = payload
    },
    set_all_bancos (state, payload) {
      state.all_bancos = payload
      order_list_by(state.all_bancos, 'nombre')
    },
    set_bancos (state, payload) {
      state.bancos = payload
      order_list_by(state.bancos, 'nombre')
    }
  },
  actions: {
    async get_all_cuentas ({ commit }) {
      try {
        const res = await fetch(`${config.BASE_URL}/allCuentas`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': store.state.long_token
          }
        })
  
        const data = await res.json()
  
        if (data.exito == 1) {
          commit('set_all_cuentas', data.data)
        } else {
          commit('set_all_cuentas', [])
          store.dispatch('show_snackbar', {
            text: data.message,
            color: 'error'
          })
        }
  
      } catch (error) {
        commit('set_all_cuentas', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_all_bancos ({ commit }, empresa) {
      try {
        const res = await fetch(`${config.BASE_URL}/allBancos?empresa=${empresa}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': store.state.long_token
          }
        })
  
        const data = await res.json()
  
        if (data.exito == 1) {
          commit('set_all_bancos', data.data)
        } else {
          commit('set_all_bancos', [])
          store.dispatch('show_snackbar', {
            text: data.message,
            color: 'error'
          })
        }
  
      } catch (error) {
        commit('set_all_bancos', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_tipos_movimiento ({ state, commit }) {
      if (state.tipos_movimiento.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/bancos/tiposMovimiento`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            commit('set_tipos_movimiento', data.data)
          } else {
            commit('set_tipos_movimiento', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
    
        } catch (error) {
          commit('set_tipos_movimiento', [])
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      }
    },
    async inhabilitar_cuenta ({}, codigo) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/bancos/inhabilitarCuenta`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ codigo: codigo })
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async habilitar_cuenta ({}, codigo) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/bancos/habilitarCuenta`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ codigo: codigo })
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async editar_cuenta ({}, cuenta) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/bancos/editarCuenta`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(cuenta)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async nueva_cuenta ({}, cuenta) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/bancos/nuevaCuenta`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(cuenta)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    // obtiene los bancos habilitados y los guarda en el state para utilizarlos en otros formularios
    async get_bancos_habilitados ({ commit, dispatch }, empresa) {
      await dispatch('get_bancos', { inhabilitado: 0, empresa: empresa })
        .then((res) => {
          commit('set_bancos', res.data)
        })
        .catch((error) => {
          commit('set_bancos', [])
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
    },
    async inhabilitar_banco ({}, codigo) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/bancos/inhabilitarBanco`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ codigo: codigo })
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async habilitar_banco ({}, codigo) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/bancos/habilitarBanco`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ codigo: codigo })
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async editar_banco ({}, nombre_y_codigo) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/bancos/editarBanco`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(nombre_y_codigo)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async nuevo_banco ({}, nombre) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/bancos/nuevoBanco`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ nombre: nombre })
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_tipos_cuenta ({ state, commit }) {
      try {
        // solo si el vector es vacio trae los tipos
        if (state.tipos_cuenta.length == 0) {
          const res = await fetch(`${config.BASE_URL}/bancos/tiposCuenta`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_tipos_cuenta', data.data)
          } else {
            commit('set_tipos_cuenta', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_tipos_cuenta', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_cuentas ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/bancos/getCuentas`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(filtro)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_bancos ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/bancos/getBancos`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(filtro)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_bancos_pais({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let bancosPeticion = await fetch(`${config.BASE_URL}/bancos/getBancosPais`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let bancos = await bancosPeticion.json()
          return resolve(bancos)
        }catch(error){
          return resolve({
            exito: 0,
            message: 'Ocurrio un problema al ejecutar el metodo get_bancos_pais: ' + error.message
          })
        }
      })
    },
    async getAllBancosPais () {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/bancos/bancosPais`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async iniciarFormularioBancos({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let bancosPeticion = await fetch(`${config.BASE_URL}/initFormBancosPais`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let bancos = await bancosPeticion.json()
          return resolve(bancos)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al iniciar el formulario de Bancos: ' + error.message
          })
        }
      })
    },
    async buscarBancosPais({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/getBancosPais?id_banco=${payload.id_banco}&inhabilitado=${payload.inhabilitado}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          
          resolve(data);
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al obtener los detalles de bancos: " + error.message
          })
        }
      })
    },
    //Nuevo feriado
    async nuevoBanco({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/newBancoPais`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
              body: JSON.stringify(payload)
          })
          const data = await res.json();
          
          resolve(data);
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al crear el Banco País: " + error.message
          })
        }
      })
    },
    //Modificar detalles del feriado
    async actualizarBanco({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/modificarBancoPais`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
             body: JSON.stringify(payload)
          })
          const data = await res.json();
          
          resolve(data);
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al modificar el Banco País: " + error.message
          })
        }
      })
    },
    async eliminarBanco({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/deleteBancoPais`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
             body: JSON.stringify(payload)
          })
          const data = await res.json();
          
          resolve(data);
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al eliminar el Banco País: " + error.message
          })
        }
      })
    },
  }
}