import config from '../../config'
import store from '../store'
import { order_list_by } from '../util/utils'

export default {
  namespaced: true,
  state: {
    formas_pago: [],
    formas_pago_cod: []
  },
  mutations: {
    set_formas_pago (state, payload) {
      state.formas_pago = payload
      order_list_by(state.formas_pago, 'nombre')
      localStorage.setItem('formas_pago', JSON.stringify(payload))
    },
    set_formas_pago_cod (state, payload) {
      state.formas_pago_cod = payload
      order_list_by(state.formas_pago_cod, 'nombre')
    }
  },
  actions: {
    async get_formas_pago () {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/pagos/formasPago`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    // obtiene las formas de pago habilitadas y las guarda en el localStorage para utilizarlas en otros formularios
    async get_formas_pago_habilitadas ({ commit, dispatch, state }) {
      if (state.formas_pago.length == 0) {
        await dispatch('get_formas_pago', { inhabilitada: 0 })
          .then((res) => {
            commit('set_formas_pago', res.data)
          })
          .catch(error => {
            commit('set_formas_pago', [])
            store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
      }
    },
    async get_formas_pago_cod ({ state, commit }) {
      if (state.formas_pago_cod.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/pagos/formasPagoCod`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
      
          const data = await res.json()
      
          if (data.exito == 1) {
            commit('set_formas_pago_cod', data.data)
          } else {
            commit('set_formas_pago_cod', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
      
        } catch (error) {
          commit('set_formas_pago_cod', [])
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      }
    }
  }
}