import moment from 'moment'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

/** TODOS LOS TIPO DE ARCHIVO QUE ACEPTA JARVIS 2.0 VAN EN ESTE ARRAY
 *  (los mime deben ser todos distintos, en el caso que exista un mismo
 *  mime con dos extensiones distintas (como el caso de jpg y jpeg),
 *  tiraría error)
 */
const tipos_archivos = [
  // pdf
  { extension: '.pdf', mime: 'application/pdf', icono: 'far fa-file-pdf', color: 'red darken-4', tipo: 'PDF' },
  // imagenes
  { extension: '.png', mime: 'image/png', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMG' },
  { extension: '.jpg', mime: 'image/jpeg', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMG' },
  { extension: '.jpe', mime: 'image/jpeg', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMG' },
  { extension: '.jif', mime: 'image/jpeg', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMG' },
  { extension: '.jfi', mime: 'image/jpeg', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMG' },
  { extension: '.jpeg', mime: 'image/jpeg', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMG' },
  { extension: '.jfif', mime: 'image/jpeg', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMG' },
  { extension: '.webp', mime: 'image/webp', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMG' },
  // documentos de texto
  { extension: '.txt', mime: 'text/plain', icono: 'far fa-file-alt', color: '', tipo: 'TXT' },
  { extension: '.doc', mime: 'application/msword', icono: 'far fa-file-word', color: 'blue darken-4', tipo: 'DOC' },
  { extension: '.docx', mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', icono: 'far fa-file-word', color: 'blue darken-4', tipo: 'DOC' },
  { extension: '.odt', mime: 'application/vnd.oasis.opendocument.text', icono: 'far fa-file-word', color: 'blue darken-4', tipo: 'DOC' },
  // hojas de cálculo
  { extension: '.csv', mime: 'text/csv', icono: 'fas fa-file-csv', color: 'green darken-4', tipo: 'CSV' },
  { extension: '.xls', mime: 'application/vnd.ms-excel', icono: 'far fa-file-excel', color: 'green darken-4', tipo: 'XLS' },
  { extension: '.xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', icono: 'far fa-file-excel', color: 'green darken-4', tipo: 'XLS' },
  { extension: '.ods', mime: 'application/vnd.oasis.opendocument.spreadsheet', icono: 'far fa-file-excel', color: 'green darken-4', tipo: 'XLS' },
  // video
  { extension: '.mp4', mime: 'video/mp4', icono: 'fas fa-film', color: 'amber darken-1', tipo: 'VID' },
  { extension: '.avi', mime: 'video/x-msvideo', icono: 'fas fa-film', color: 'amber darken-1', tipo: 'VID' },
  { extension: '.mpg', mime: 'video/mpeg', icono: 'fas fa-film', color: 'amber darken-1', tipo: 'VID' },
  { extension: '.webm', mime: 'video/webm', icono: 'fas fa-film', color: 'amber darken-1', tipo: 'VID' },
]

// mascara para el formato de numeros enteros
const number_mask = createNumberMask({
  prefix: '',
  thousandsSeparatorSymbol: '.',
  includeThousandsSeparator: true,
  allowNegative: false,
})

// regex para validar email
const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// regex para validar fecha de vencimientos de tarjetas
export const REGEX_VENC_TAR = /\b(0[1-9]|1[0-2])(\/)\d{2}\b/

// el formato de la fecha debe ser DD MM YYYY (en el orden que se desee) separados por - o /
// si no se especifica el formato, se convierte a DD/MM/YYYY
function format_date (date, format) {
  if (!date) return ''
  return moment(date).format(format ? format : 'DD/MM/YYYY')
}

// money debe ser de tipo numérico o flotante
function format_money (money) {
  if (!money && money != 0) return ''
  return new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(money)
}

export function format_money_round (money, precision) {
  if (!money && money != 0) return ''
  return new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS', maximumFractionDigits: precision? precision : 0 }).format(money)
}

// devuelve un string (a partir de un numero) formateado a dos digitos decimales
function format_2_decimales (value) {
  if (!value) return ''
  return (parseFloat(value).toFixed(2)).toString().replace('.', ',')
}

// devuelve el periodo de los cant_mes anteriores al mes actual en formato 01//MM/YYYY
function get_last_periodo (cant_mes) {
  // si no se indica cant_mes devuelve el del mes anterior
  if  (!cant_mes) return moment(new Date()).subtract(1, 'months').startOf('month').format('DD/MM/YYYY')
  return moment(new Date()).subtract(cant_mes, 'months').startOf('month').format('DD/MM/YYYY')
}

// devuelve el periodo siguiente peiodo especificado en formato 01//MM/YYYY
function get_next_periodo (periodo) {
  // si no se indica un periodo devuelve el del mes siguiente al periodo actual
  if (!periodo) return moment(new Date()).add(1, 'months').startOf('month').format('DD/MM/YYYY')
  return moment(periodo, 'DD/MM/YYYY').add(1, 'months').startOf('month').format('DD/MM/YYYY')
}

// devuelve el periodo actual en formato 01//MM/YYYY
function get_periodo () {
  return moment(new Date()).startOf('month').format('DD/MM/YYYY')
}

// funcion para convertir un string base64 en un array buffer
function base64ToArrayBuffer (base64) {
  let len = base64.length
  let bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
      bytes[i] = base64.charCodeAt(i)
  }
  return bytes.buffer
}

export function ArrayBufferToBase64 (buffer) {
  let binary = ''
  let bytes = new Uint8Array(buffer)
  let len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

// convierte un archivo en base64
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

// ordena una lista de onjetos segun el atributo (key) especificado
// se pude elegir entre desc y asc (con orden), si no se indica alguno por defecto ordena asc
function order_list_by (list, key, orden) {
  let desc = -1
  let asc = 1
  if (orden == 'desc') {
    desc = 1
    asc = -1
  }
  list.sort(function(a, b) {
    if(a[key] < b[key]) { return desc }
    if(a[key] > b[key]) { return asc }
    return 0
  })
}

export function order_list_by_date(list, key, orden){
  let desc = -1
  let asc = 1
  if (orden == 'asc') {
    desc = 1
    asc = -1
  }
  list.sort(function(a, b) {
    let c = new Date(a[key])
    let d = new Date(b[key])
    if(c[key] < d[key]) { return desc }
    if(c[key] > d[key]) { return asc }
    return 0
  })
}

export function order_list_by_date2(list, key, orden){
  let desc = -1
  let asc = 1
  if (orden == 'asc') {
    desc = 1
    asc = -1
  }
  
  list.sort(function(a, b) {
    let c = moment(a[key]).toDate()
    let d = moment(b[key]).toDate()
    if(c < d) { return desc }
    if(c > d) { return asc }
    return 0
  })
}

/**
 * Esta funcion sirve para obtener el nombre de un item dentro de un array buscando por una key
 * 
 *  Ej: si se quiere obtener el nombre de una empresa mediante su id se debe indicar el array de emrpesas
 *      almacenado en el local storage y la key por la que va a buscar la empresa, en este caso el id. La
 *      key debe ser única como un codigo/id para obtener el nombre correcto. Ejemplos de uso:
 * 
 *      const nombre_empresa = get_nombre_obj_arr(this.empresas, 'id', this.vendedor.empresa_id)
 *      const nombre_categoria = get_nombre_obj_arr(this.categorias, 'codigo', this.articulo.categoria_codigo)
 * 
 *  Notas: - si no encuentra ninguna coincidencia devuelve ''
 *         - atributo se utiliza cunando el nombre que se quiere obtener no se llama nombre. Por ejemplo si se
 *           quiere el nombre corto de una empresa y no su nombre completo seria:
 * 
 *           const emp_abreviacion = get_nombre_obj_arr(this.empresas, 'id', this.vendedor.empresa_id, 'nombre_corto')
 * 
 */
function get_nombre_obj_arr (array, key, value, atributo) {
  const item = array.find(i => i[key] == value)
  if (item) {
    if (atributo) {
      return item[atributo]
    } else {
      return item.nombre
    }
  } else {
    return ''
  }
}

/**
 *  Devuelve true si la fecha 1 es menor que la fecha 2 y false
 *  si la fecha 2 es mayor que la fecha 1
 * 
 *  Nota: el formato de ambas fechas debe ser DD/MM/YYYY
 */
function valdiar_rango_fecha (fecha_1, fecha_2) {
  const fecha_desde = parseDate(fecha_1)
  const fecha_hasta = parseDate(fecha_2)
  if (fecha_desde && fecha_hasta) {
    return moment(fecha_hasta).isSameOrBefore(fecha_desde)
  } else {
    return false
  }
}

// convierte una fecha en formato DD/MM/YYYY a YYYY-MM-DD
function parseDate (date) {
  if (!date) return null
  const [day, month, year] = date.split('/')
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
}

// devuelve un objeto con el ancho y alto de una imagen en base64
async function getDims (base64) {
  return await new Promise((resolve) => {
    let img = new Image()
    img.src = base64
    img.onload = () => {
      resolve({
        ancho: img.width,
        alto: img.height
      })
    }
  })
}

// mismo funcionamiento que el nvl de oracle
function nvl (value, newValue) {
  if (value === null) {
    return newValue
  } else {
    return value
  }
}

// como nvl pero devuelve el valor en string
export function str (value, newValue) {
  if (value == null) return newValue
  else return value.toString()
}

export function objetoNoVacio(obj){
  if (obj == null || obj == undefined || typeof obj != 'object' || Array.isArray(obj) || Object.keys(obj).length == 0) return false
  else return true
}

export function cadenaVacia(cadena){
  if (cadena == null || cadena == undefined || cadena.toString().length == 0) return true
  else return false
}

/**
 * Nota: esta funcion divide un array en sub arrays de longitud n
 */
 export function divideArray (array, longitud) {
  let result = []
  while (array.length > 0) {
    result.push(array.splice(0, longitud))
  }
  return result
}

export function convertDecimals(numero){
  return parseFloat(parseFloat(numero.toString().replace(',', '.')).toFixed(2).toString().replace(',', '.'))
}

export function randomNumber(min, max){
  // genero un numero aleatorio entero entre min y max
  return Math.floor(Math.random() * (max - min) + min)
}

export function convertDecimalsPlus(numero){
  return parseFloat(parseFloat(parseFloat(parseFloat(numero.toString().replace(',', '.')).toFixed(4).toString().replace(',', '.')).toFixed(3).toString().replace(',', '.')).toFixed(2).toString().replace(',', '.'))
}

export function roundNumber(num, scale) {
  num = num.toString().replace(',', '.');
  if(!("" + num).includes("e")) {
      return +(Math.round(num + "e+" + scale)  + "e-" + scale);
  } else {
      var arr = ("" + num).split("e");
      var sig = ""
      if(+arr[1] + scale > 0) {
          sig = "+";
      }
      return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
  }
}

// formatea un valor con comillas simples si el valor es null, undefined, '' o 0
// se utiliza para dar formato a filtros de tipo query que son de un select p.e.
export function formatQuery (valor) {
  return valor ? valor : ''
}

// devuelve la extension de un nombre de archivo
export function getExtensionFileName (file_name) {
  return file_name.substring(file_name.lastIndexOf('.'), file_name.size)
}

// redondea un numero con el tu fixed, si no se le pasa los decimales redondea a 2 por defecto
export function roundNumberTF (value, decimals) {
  if (!value) return 0
  else return roundNumber(value, decimals ? decimals : 2)
}

// funcion q devuelve un string con el formato para hacer consultas query a partir de un objeto
export function objectToQuery (object) {
  let query = ''
  for (const key in object) {
    if (Object.hasOwnProperty.call(object, key)) {
      query += `${key}=${object[key]}&`
    }
  }
  // elimino el ultimo &
  if (query != '') {
    query = query.substring(0, query.length - 1)
  }
  return query
}

export {
  tipos_archivos,
  number_mask,
  REGEX_EMAIL,
  format_date,
  format_money,
  format_2_decimales,
  get_last_periodo,
  get_next_periodo,
  get_periodo,
  getBase64,
  order_list_by,
  get_nombre_obj_arr,
  valdiar_rango_fecha,
  parseDate,
  getDims,
  nvl,
}