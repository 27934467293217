import config from '../../config'
import store from '../store'

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async buscarVenta({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let searchPeticion = await fetch(`${config.BASE_URL}/adminCambioDirecto/buscarVenta?venta_id=${datos.venta_id}&imei_bus=${datos.imei_bus}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let search = await searchPeticion.json()
          search.msj = search.message
          return resolve(search)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo buscarVenta: ' + error.message
          })
        }
      })
    },
    async reemplazarImei({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let reemplazoPeticion = await fetch(`${config.BASE_URL}/adminCambioDirecto/reemplazarImei`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let reemplazo = await reemplazoPeticion.json()
          reemplazo.msj = reemplazo.message
          return resolve(reemplazo)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo reemplazarImei: ' + error.message
          })
        }
      })
    }
  }
}