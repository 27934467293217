import config from '../../../config'
import store from '../../store'

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    // actualiza los precios
    async actualizar () {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/precios/actualizar`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    // payload = array con: articulo_codigo, lista_codigo, precio y vigencia en formato DD/MM/YYYY
    async insertar ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/precios/insertar`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    // payload = array de rubros, array de categorias, array de listas, dias, porcentaje
    async get_precios ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/precios/getPrecios`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async format_data ({}, array) {
      return await new Promise(async (resolve, reject) => {
        let data = []
        for (let index = 0; index < array.length; index++) {
          const item = array[index]
          data.push({
            art: item.articulo_codigo,
            lis: item.lista_codigo,
            pre: item.precio,
            fec: item.vigencia
          })
        }
        resolve(data)
      })
    }
  }
}