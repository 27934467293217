import config from '../../../config'
import store from '../../store'
import { format_date, order_list_by } from '../../util/utils'

export default {
  namespaced: true,
  state: {
    tipos_comp: [],
    tipificaciones: [],
    estados: [],
    tipos_gastos: [],
    empresas_emit: [],
    tipos_trans: [],
  },
  mutations: {
    set_tipos_comp (state, payload) {
      order_list_by(payload, 'nombre')
      state.tipos_comp = payload
    },
    set_tipificaciones (state, payload) {
      order_list_by(payload, 'nombre')
      state.tipificaciones = payload
    },
    set_estados (state, payload) {
      order_list_by(payload, 'nombre')
      state.estados = payload
    },
    set_tipos_gastos (state, payload) {
      order_list_by(payload, 'nombre')
      state.tipos_gastos = payload
    },
    set_empresas (state, payload) {
      order_list_by(payload, 'nombre')
      state.empresas_emit = payload
    },
    set_tipos_trans (state, payload) {
      order_list_by(payload, 'nombre')
      state.tipos_trans = payload
    },
    set_tipificacion (state, payload) {
      let tipificacion = state.tipificaciones.find(t => t.id == payload.id)
      Object.assign(tipificacion, payload)
    },
    add_tipificacion (state, payload) {
      state.tipificaciones.push(payload)
      order_list_by(state.tipificaciones, 'nombre')
    }
  },
  actions: {
    async get_desagregos ({}, datos) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlCaja/desagregos?num_comp=${datos.num_comp}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async editar_empresa ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlCaja/editarGastoEmpresa`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async inhabilitar_tipificacion ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlCaja/inhabilitarTipif`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ id: id })
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async habilitar_tipificacion ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlCaja/habilitarTipif`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ id: id })
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async editar_tipificacion ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlCaja/editarTipif`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async nueva_tipificacion ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlCaja/nuevaTipif`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_tipos_trans ({ state, commit }) {
      if (state.tipos_trans.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/controlCaja/tiposTrans`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            commit('set_tipos_trans', data.data)
          } else {
            commit('set_tipos_trans', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
    
        } catch (error) {
          commit('set_tipos_trans', [])
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      }
    },
    async guardar ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlCaja/guardar`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_archivos ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlCaja/archivos?id=${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_gastos_empresas ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlCaja/getGastosEmpresas`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_cajas ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlCaja/getCajas`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_estados ({ state, commit }) {
      if (state.estados.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/controlCaja/estadosComp`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            commit('set_estados', data.data)
          } else {
            commit('set_estados', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
    
        } catch (error) {
          commit('set_estados', [])
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      }
    },
    async get_empresas ({ state, commit }) {
      if (state.empresas_emit.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/controlCaja/empresas`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            commit('set_empresas', data.data)
          } else {
            commit('set_empresas', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
    
        } catch (error) {
          commit('set_empresas', [])
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      }
    },
    async get_tipos_gastos ({ state, commit }) {
      if (state.tipos_gastos.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/controlCaja/tiposGastos`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            commit('set_tipos_gastos', data.data)
          } else {
            commit('set_tipos_gastos', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
    
        } catch (error) {
          commit('set_tipos_gastos', [])
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      }
    },
    async get_tipos ({ state, commit }) {
      if (state.tipos_comp.length == 0 || state.tipificaciones.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/controlCaja/tiposComp`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            commit('set_tipos_comp', data.data.comprobantes)
            commit('set_tipificaciones', data.data.tipificacion)
          } else {
            commit('set_tipos_comp', [])
            commit('set_tipificaciones', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
    
        } catch (error) {
          commit('set_tipos_comp', [])
          commit('set_tipificaciones', [])
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      }
    },
    async init_form_tipos_comp({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let initPeticion = await fetch(`${config.BASE_URL}/controlCaja/initFormTiposComp`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let init = await initPeticion.json()
          init.msj = init.message
          return resolve(init)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo init_form_tipos_comp: ' + error.message
          })
        }
      })
    },
    async get_tipos_comprobantes({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let detallesPeticion = await fetch(`${config.BASE_URL}/controlCaja/getTiposComprobantes?empresa_codigo=${datos.empresa_codigo}&tipo_movimiento=${datos.tipo_movimiento}&tipo_caja=${datos.tipo_caja}&estado_codigo=${datos.estado_codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let detalles = await detallesPeticion.json()
          detalles.msj = detalles.message
          return resolve(detalles)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo get_tipos_comprobantes: ' + error.message
          })
        }
      })
    },
    async nuevo_tipo_comprobante({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let nuevoPeticion = await fetch(`${config.BASE_URL}/controlCaja/nuevoTipoComp`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let nuevo = await nuevoPeticion.json()
          nuevo.msj = nuevo.message
          return resolve(nuevo)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo nuevo_tipo_comprobante: ' + error.message
          })
        }
      })
    },
    async actualizar_tipo_comp({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let updatePeticion = await fetch(`${config.BASE_URL}/controlCaja/actualizarTipoComp`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let update = await updatePeticion.json()
          update.msj = update.message
          return resolve(update)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo actualizar_tipo_comp: ' + error.message
          })
        }
      })
    }
  }
}