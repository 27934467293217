import config from '../../../config'
import store from '../../store'

export default {
  namespaced: true,
  state: {
    tipos_cheque: [],
    estados_cheque: [],
    formatos_cheque: [],
    banco_tipos_mov: []
  },
  mutations: {
    set_tipos (state, payload) {
      state.tipos_cheque = payload
    },
    set_estados (state, payload) {
      state.estados_cheque = payload
    },
    set_formatos (state, payload) {
      state.formatos_cheque = payload
    },
    set_banco_tipos_mov (state, payload){
      state.banco_tipos_mov = payload
    }
  },
  actions: {
    async get_permiso_editar () {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/cheques/permisoEditar`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
      
          const data = await res.json()
  
          if (data.exito == 1) {
            resolve(data.permiso == 1 ? true : false)
          }
          else {
            resolve(false)
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
      
        } catch (error) {
          resolve(false)
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      })
    },
    async get_historial ({}, codigo) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/cheques/historial?cheque=${codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async editar ({}, cheque) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/cheques/editarCheque`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(cheque)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_cheque ({}, codigo) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/cheques?codigo=${codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_cheques ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/cheques/getCheques`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(filtro)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_formatos ({ state, commit }) {
      try {
        if (state.formatos_cheque.length == 0) {
          const res = await fetch(`${config.BASE_URL}/cheques/formatos`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_formatos', data.data)
          } else {
            commit('set_formatos', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_formatos', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_estados ({ state, commit }) {
      try {
        if (state.estados_cheque.length == 0) {
          const res = await fetch(`${config.BASE_URL}/cheques/estados`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_estados', data.data)
          } else {
            commit('set_estados', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_estados', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_tipos ({ state, commit }) {
      try {
        if (state.tipos_cheque.length == 0) {
          const res = await fetch(`${config.BASE_URL}/cheques/tipos`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_tipos', data.data)
          } else {
            commit('set_tipos', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_tipos', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async cambiar_estado ({}, cheques) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/cheques/cambiarEstado`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ cheques: cheques })
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_banco_tipos_mov({commit}){
      try {
        const res = await fetch(`${config.BASE_URL}/cheques/bancoTiposMov`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': store.state.long_token
          }
        })

        const data = await res.json()
        if (data.exito == 1) {
          commit('set_banco_tipos_mov', data.data)
        } else {
          commit('set_banco_tipos_mov', [])
          store.dispatch('show_snackbar', {
            text: data.message,
            color: 'error'
          })
        }
      } catch (error) {
        commit('set_banco_tipos_mov', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    }
  }
}