import store from "../store"
import config from "../../config"

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async getProvincias(){
      return await new Promise(async (resolve, reject) => {
        try{
          let provinciasPeticion = await fetch(`${config.BASE_URL}/domicilios/getProvincias`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let provincias = await provinciasPeticion.json()
          return resolve(provincias)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getProvincias: ' + error.message
          })
        }
      })
    },
    async getLocalidadesXProvincia({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let localidadesPeticion = await fetch(`${config.BASE_URL}/domicilios/getLocalidadesXProvincia?provincia_id=${datos.provincia_id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let localidades = await localidadesPeticion.json()
          return resolve(localidades)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getLocalidadesXProvincia: ' + error.message
          })
        }
      })
    },
    async getLocalidadXCodPostal({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let localidadesPeticion = await fetch(`${config.BASE_URL}/locXcp`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let localidades = await localidadesPeticion.json()
          return resolve(localidades)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getLocalidadXCodPostal: ' + error.message
          })
        }
      })
    }
  }
}