import config from "../../../config"
import store from "../../store"

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async initIndexOC({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let initPeticion = await fetch(`${config.BASE_URL}/adminOrdenesCompra/initIndexOC`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let init = await initPeticion.json()
          init.msj = init.message
          return resolve(init)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo initIndexOC: ' + error.message
          })
        }
      })
    },
    async getOrdenesCompra({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let ordenesPeticion = await fetch(`${config.BASE_URL}/adminOrdenesCompra/getOrdenesCompra?empresa_codigo=${datos.empresa_codigo}&fecha_desde=${datos.fecha_desde}&fecha_hasta=${datos.fecha_hasta}&usuario=${datos.usuario}&proveedor_codigo=${datos.proveedor_codigo}&estado_codigo=${datos.estado_codigo}&recepcionado=${datos.recepcionado}&facturado=${datos.facturado}&pagado=${datos.pagado}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let ordenes = await ordenesPeticion.json()
          ordenes.msj = ordenes.message
          return resolve(ordenes)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getOrdenesCompra: ' + error.message
          })
        }
      })
    },
    async getOrdenesCompra2({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let ordenesPeticion = await fetch(`${config.BASE_URL}/adminOrdenesCompra/getOrdenesCompra2`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let ordenes = await ordenesPeticion.json()
          ordenes.msj = ordenes.message
          return resolve(ordenes)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getOrdenesCompra2: ' + error.message
          })
        }
      })
    },
    async anularOrdenCompra({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let anularPeticion = await fetch(`${config.BASE_URL}/adminOrdenesCompra/anularOrdenCompra`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let anular = await anularPeticion.json()
          anular.msj = anular.message
          return resolve(anular)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo anularOrdenCompra: ' + error.message
          })
        }
      })
    },
    async volverEstadoOrdCompra({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let volverPeticion = await fetch(`${config.BASE_URL}/adminOrdenesCompra/volverEstadoOrdCompra`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let volver = await volverPeticion.json()
          volver.msj = volver.message
          return resolve(volver)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo volverEstadoOrdCompra: ' + error.message
          })
        }
      })
    },
    async getExcelOC({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let excelPeticion = await fetch(`${config.BASE_URL}/adminOrdenesCompra/getExcelOC?orden_id=${datos.orden_id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let excel = await excelPeticion.json()
          excel.msj = excel.message
          return resolve(excel)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getExcelOC: ' + error.message
          })
        }
      })
    },
    async initFormUnaOC({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let initPeticion = await fetch(`${config.BASE_URL}/adminOrdenesCompra/initFormUnaOC?nuevo=${datos.nuevo}&orden_id=${datos.orden_id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let init = await initPeticion.json()
          init.msj = init.message
          return resolve(init)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo initFormUnaOC: ' + error.message
          })
        }
      })
    },
    async getArtsAndPedidos({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let infoPeticion = await fetch(`${config.BASE_URL}/adminOrdenesCompra/getArtsAndPedidos?empresa_codigo=${datos.empresa_codigo}&proveedor_codigo=${datos.proveedor_codigo}&todos=${datos.todos}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let info = await infoPeticion.json()
          info.msj = info.message
          return resolve(info)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getArtsAndPedidos: ' + error.message
          })
        }
      })
    },
    async getArticuloData({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let infoPeticion = await fetch(`${config.BASE_URL}/adminOrdenesCompra/getArticuloData?articulo_codigo=${datos.articulo_codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let info = await infoPeticion.json()
          info.msj = info.message
          return resolve(info)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getArticuloData: ' + error.message
          })
        }
      })
    },
    async saveOrdenCompra({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let savePeticion = await fetch(`${config.BASE_URL}/adminOrdenesCompra/saveOrdenCompra`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let save = await savePeticion.json()
          save.msj = save.message
          return resolve(save)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo saveOrdenCompra: ' + error.message
          })
        }
      })
    },
    async updateOrdenCompra({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let updatePeticion = await fetch(`${config.BASE_URL}/adminOrdenesCompra/updateOrdenCompra`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let update = await updatePeticion.json()
          update.msj = update.message
          return resolve(update)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo updateOrdenCompra: ' + error.message
          })
        }
      })
    },
    async getDetallesPedidos({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let detallesPeticion = await fetch(`${config.BASE_URL}/adminOrdenesCompra/getDetallesPedidos`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let dets = await detallesPeticion.json()
          dets.msj = dets.message
          return resolve(dets)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getDetallesPedidos: ' + error.message
          })
        }
      })
    },
    async getAllArticles({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let artsPeticion = await fetch(`${config.BASE_URL}/adminOrdenesCompra/getAllArticles`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let arts = await artsPeticion.json()
          arts.msj = arts.message
          return resolve(arts)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getAllArticles: ' + error.message
          })
        }
      })
    },
    async asociarArticuloProveedor({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let asociarPeticion = await fetch(`${config.BASE_URL}/adminOrdenesCompra/asociarArticuloProveedor`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let asociar = await asociarPeticion.json()
          asociar.msj = asociar.message
          return resolve(asociar)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo asociarArticuloProveedor: ' + error.message
          })
        }
      })
    },
    async getFacturasCompra({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let facturasPeticion = await fetch(`${config.BASE_URL}/adminOrdenesCompra/getFacturasCompra?orden_id=${datos.orden_id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let facturas = await facturasPeticion.json()
          facturas.msj = facturas.message
          return resolve(facturas)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getFacturasCompra: ' + error.message
          })
        }
      })
    },
    async getRecepciones({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let recepPeticion = await fetch(`${config.BASE_URL}/adminOrdenesCompra/getRecepciones?orden_id=${datos.orden_id}&factura_id=${datos.factura_id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let recepciones = await recepPeticion.json()
          recepciones.msj = recepciones.message
          return resolve(recepciones)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getRecepciones: ' + error.message
          })
        }
      })
    },
    async marcarPagada({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let marcarPeticion = await fetch(`${config.BASE_URL}/adminOrdenesCompra/marcarPagada`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let marcar = await marcarPeticion.json()
          marcar.msj = marcar.message
          return resolve(marcar)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo marcarPagada: ' + error.message
          })
        }
      })
    },
    async marcarNoPagada({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let marcarPeticion = await fetch(`${config.BASE_URL}/adminOrdenesCompra/marcarNoPagada`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let marcar = await marcarPeticion.json()
          marcar.msj = marcar.message
          return resolve(marcar)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo marcarNoPagada: ' + error.message
          })
        }
      })
    },
    async marcarEmitida({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let marcarPeticion = await fetch(`${config.BASE_URL}/adminOrdenesCompra/marcarEmitida`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let marcar = await marcarPeticion.json()
          marcar.msj = marcar.message
          return resolve(marcar)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo marcarEmitida: ' + error.message
          })
        }
      })
    },
    async getOrdCompraAsoc({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let ordenesPeticion = await fetch(`${config.BASE_URL}/adminOrdenesCompra/getOrdCompraAsoc?factura_id=${datos.factura_id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let ordenes = await ordenesPeticion.json()
          ordenes.msj = ordenes.message
          return resolve(ordenes)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getOrdCompraAsoc: ' + error.message
          })
        }
      })
    },
    async marcarFinalizada({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let marcarPeticion = await fetch(`${config.BASE_URL}/adminOrdenesCompra/marcarFinalizada`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let marcar = await marcarPeticion.json()
          marcar.msj = marcar.message
          return resolve(marcar)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo marcarFinalizada: ' + error.message
          })
        }
      })
    },
    async marcarFinalizadaMuchas({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let marcarPeticion = await fetch(`${config.BASE_URL}/adminOrdenesCompra/marcarFinalizadaMuchas`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let marcar = await marcarPeticion.json()
          return resolve(marcar)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo marcarFinalizadaMuchas: ' + error.message
          })
        }
      })
    },
    async setearComentario({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let setPeticion = await fetch(`${config.BASE_URL}/adminOrdenesCompra/setearComentario`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let setComent = await setPeticion.json()
          setComent.msj = setComent.message
          return resolve(setComent)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo setearComentario: ' + error.message
          })
        }
      })
    },
    async cambiarFechaOC({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let cambioPeticion = await fetch(`${config.BASE_URL}/adminOrdenesCompra/cambiarFechaOC`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let cambio = await cambioPeticion.json()
          cambio.msj = cambio.message
          return resolve(cambio)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo cambiarFechaOC: ' + error.message
          })
        }
      })
    },
    async editar_proveedor ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminOrdenesCompra/editarProveedor`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    }
  }
}