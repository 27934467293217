import config from '../../config'
import store from '../store'
import { order_list_by } from '../util/utils'

export default {
  namespaced: true,
  state: {
    estados_penal: []
  },
  mutations: {
    set_estados (state, payload) {
      order_list_by(payload, 'nombre')
      state.estados_penal = payload
    },
  },
  actions: {
    async desanular ({}, numero) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/penalizaciones/desanular`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ numero: numero })
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async anular ({}, numero) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/penalizaciones/anular`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ numero: numero })
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async editar ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/penalizaciones/editar`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async permiso () {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/penalizaciones/permiso`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()

          if (data.exito != 1) {
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
          resolve(data.permiso)
    
        } catch (error) {
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
          resolve(0)
        }
      })
    },
    async nueva ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/penalizaciones/nueva`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_penalizaciones ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/penalizaciones/getPenalizaciones`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_estados ({ state, commit }) {
      if (state.estados_penal.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/penalizaciones/estados`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            commit('set_estados', data.data)
          } else {
            commit('set_estados', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
    
        } catch (error) {
          commit('set_estados', [])
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      }
    }
  }
}