import config from "../../../config"
import store from '../../store'
import { Encrypt, Decrypt } from '../../util/aes.js'
import { VueEasyJwt } from 'vue-easy-jwt'
const jwt = new VueEasyJwt()
export default{
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async getTiposEnvio({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let tiposPeticion = await fetch(config.BASE_URL + `/adminPaquetes/getTiposEnvio`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let tipos = await tiposPeticion.json()
          if (tipos.resultado == 1){
            return resolve({
              resultado: 1,
              msj: 'OK',
              tipos: tipos.tipos
            })
          }else{
            return resolve({
              resultado: 0,
              msj: tipos.message,
              tipos: null
            })
          }
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getTiposEnvio: ' + error.message,
            tipos: null
          })
        }
      })
    },
    async getBodegasOrigen({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let usuario = Decrypt(jwt.decodeToken(store.state.long_token).credenciales.usuario).toString()
          let bodegasPeticion = await fetch(config.BASE_URL + `/adminPaquetes/getBodegasOrigen?usuario=${usuario}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let bodegas = await bodegasPeticion.json()
          if (bodegas.resultado == 1){
            return resolve({
              resultado: 1,
              msj: 'OK',
              bodegas: bodegas.bodegas
            })
          }else{
            return resolve({
              resultado: 0,
              msj: bodegas.message,
              bodegas: null
            })
          }
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getBodegasOrigen: ' + error.message,
            bodegas: null
          })
        }
      })
    },
    async getBodegasDestino({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let usuario = Decrypt(jwt.decodeToken(store.state.long_token).credenciales.usuario).toString()
          let bodegasPeticion = await fetch(config.BASE_URL + `/adminPaquetes/getBodegasDestino?usuario=${usuario}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let bodegas = await bodegasPeticion.json()
          if (bodegas.resultado == 1){
            return resolve({
              resultado: 1,
              msj: 'OK',
              bodegas: bodegas.bodegas
            })
          }else{
            return resolve({
              resultado: 0,
              msj: bodegas.message,
              bodegas: null
            })
          }
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getBodegasDestino: ' + error.message,
            bodegas: null
          })
        }
      })
    },
    async getEstadosEnvios({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let estadosPeticion = await fetch(config.BASE_URL + `/adminPaquetes/getEstadosPaquete`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let estados = await estadosPeticion.json()
          if (estados.resultado == 1){
            return resolve({
              resultado: 1,
              msj: 'OK',
              estados: estados.estados
            })
          }else{
            return resolve({
              resultado: 0,
              msj: estados.message,
              estados: null
            })
          }
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getEstadosEnvios: ' + error.message,
            estados: null
          })
        }
      })
    },
    async getProvincias({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let provinciasPeticion = await fetch(config.BASE_URL + `/adminPaquetes/getProvincias`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let provincias = await provinciasPeticion.json()
          if (provincias.resultado == 1){
            return resolve({
              resultado: 1,
              msj: 'OK',
              provincias: provincias.provincias
            })
          }else{
            return resolve({
              resultado: 0,
              msj: provincias.message,
              provincias: null
            })
          }
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getProvincias: ' + error.message,
            provincias: null
          })
        }
      })
    },
    async getRepartidores({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let repartidoresPeticion = await fetch(config.BASE_URL + `/adminPaquetes/getRepartidores`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let repartidores = await repartidoresPeticion.json()
          if (repartidores.resultado == 1){
            return resolve({
              resultado: 1,
              msj: 'OK',
              repartidores: repartidores.repartidores
            })
          }else{
            return resolve({
              resultado: 0,
              msj: repartidores.message,
              repartidores: null
            })
          }
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getRepartidores: ' + error.message,
            repartidores: null
          })
        }
      })
    },
    async initFormPaquetes({}){
      /*return await new Promise(async (resolve, reject) => {
        try{
          let res = await fetch(config.BASE_URL + `/adminPaquetes/initFormPaquetes2`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let data = await res.json()
          return resolve(data)
        }catch(error){
          return resolve({
            resultado: 0,
            message: 'Ocurrio un problema al ejecutar el metodo initFormPaquetes: ' + error.message
          })
        }
      })*/
      return await new Promise(async (resolve, reject) => {
        try{
          // obtengo el usuario conectado
          let usuario = Decrypt(jwt.decodeToken(store.state.long_token).credenciales.usuario).toString()
          let initPeticion = await fetch(config.BASE_URL + `/adminPaquetes/initFormPaquetes?usuario=${usuario}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let init = await initPeticion.json()
          return resolve(init)
        }catch(error){
          return resolve({
            resultado: 0,
            message: 'Ocurrio un problema al ejecutar el metodo initFormPaquetes: ' + error.message
          })
        }
      })
    },
    async getPaquetes({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let paquetesPeticion = await fetch(config.BASE_URL + `/adminPaquetes/getPaquetes`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let paquetes = await paquetesPeticion.json()
          if (paquetes.resultado == 1){
            return resolve({
              resultado: 1,
              msj: 'OK',
              paquetes: paquetes.paquetes
            })
          }else{
            return resolve({
              resultado: 0,
              msj: paquetes.msj,
              paquetes: null
            })
          }
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getPaquetes: ' + error.message,
            paquetes: null
          })
        }
      })
    },
    async getDetallesPaquete({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let detallesPeticion = await fetch(config.BASE_URL + `/adminPaquetes/getDetallesPaquete`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let detalles = await detallesPeticion.json()
          if (detalles.resultado == 1){
            return resolve({
              resultado: 1,
              msj: 'OK',
              paquetes: detalles.detalles
            })
          }else{
            return resolve({
              resultado: 0,
              msj: detalles.message,
              paquetes: null
            })
          }
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getDetallesPaquete: ' + error.message,
            paquetes: null
          })
        }
      })
    },
    async generateLabel({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let etiquetaPeticion = await fetch(config.BASE_URL + `/adminPaquetes/getEtiquetaLogistica`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let etiqueta = await etiquetaPeticion.json()
          etiqueta.msj = etiqueta.message
          return resolve(etiqueta)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo generateLabel: ' + error.message
          })
        }
      })
    },
    async aceptarPaquete({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let aceptarPeticion = await fetch(config.BASE_URL + `/adminPaquetes/aceptarPaquete`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let aceptar = await aceptarPeticion.json()
          if (aceptar.resultado == 1){
            return resolve({
              resultado: 1,
              msj: 'OK'
            })
          }else{
            return resolve({
              resultado: 0,
              msj: aceptar.message
            })
          }
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo aceptarPaquete: ' + error.message,
            paquetes: null
          })
        }
      })
    },
    async getMovimientosPendientes({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let pendientesPeticion = await fetch(config.BASE_URL + `/adminPaquetes/getMovimientosPendientes`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let pendientes = await pendientesPeticion.json()
          if (pendientes.resultado == 1){
            return resolve({
              resultado: 1,
              msj: 'OK',
              movimientos: pendientes.movimientos
            })
          }else{
            return resolve({
              resultado: 0,
              msj: pendientes.message,
              movimientos: null
            })
          }
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getMovimientosPendientes: ' + error.message,
            movimientos: null
          })
        }
      })
    },
    async generarMovimiento({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let movimPeticion = await fetch(config.BASE_URL + `/adminPaquetes/generarMovimiento`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let movimiento = await movimPeticion.json()
          return resolve(movimiento)
        }catch(error){
          return resolve({
            resultado: 0,
            message: 'Ocurrio un problema al ejecutar el metodo generarMovimiento: ' + error.message
          })
        }
      })
    },
    async getLocalidades({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let localidadesPeticion = await fetch(config.BASE_URL + `/domicilios/getLocalidadesTMP?provinciaId=${datos.idProvincia}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let localidades = await localidadesPeticion.json()
          if (localidades.resultado == 1){
            return resolve({
              resultado: 1,
              msj: 'OK',
              localidades: localidades.localidades
            })
          }else{
            return resolve({
              resultado: 0,
              msj: localidades.message,
              localidades: null
            })
          }
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getLocalidades: ' + error.message,
            localidades: null
          })
        }
      })
    },
    async newPackage({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let paquetePeticion = await fetch(config.BASE_URL + `/adminPaquetes/nuevoPaquete`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let paquete = await paquetePeticion.json()
          paquete.msj = paquete.message
          return resolve(paquete)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo newPackage: ' + error.message,
            nuevoPaquete: null
          })
        }
      })
    },
    async generarLote({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let lotePeticion = await fetch(config.BASE_URL + `/adminPaquetes/generarLote`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let lote = await lotePeticion.json()
          lote.msj = lote.message
          return resolve(lote)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo newPackage: ' + error.message
          })
        }
      })
    }
  }
}