import config from '../../../config'
import store from '../../store'

export default{
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    //Iniciar Formulario
    async iniciarFormulario({}){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/initFormFeriados`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          
          resolve(data);
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al iniciar el formulario de Feriados: " + error.message
          })
        }
      })
    },
    //BuscarDetalles
    async buscarFeriados({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/getFeriados?anio=${payload.anio}&sucursal_codigo=${payload.sucursal_codigo}&tipo_id=${payload.tipo_id}&fecha=${payload.fecha}&feriado_nombre=${payload.feriado_nombre}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          
          resolve(data);
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al obtener los detalles de los feriados: " + error.message
          })
        }
      })
    },
    //Nuevo feriado
    async nuevoFeriado({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/newFeriado`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
              body: JSON.stringify(payload)
          })
          const data = await res.json();
          
          resolve(data);
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al crear el feriado: " + error.message
          })
        }
      })
    },
    //Modificar detalles del feriado
    async actualizarFeriado({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/modificarFeriado`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
             body: JSON.stringify(payload)
          })
          const data = await res.json();
          
          resolve(data);
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al modificar el feriado: " + error.message
          })
        }
      })
    },
    async eliminarFeriado({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/deleteFeriado`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
             body: JSON.stringify(payload)
          })
          const data = await res.json();
          
          resolve(data);
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al eliminar el Feriado: " + error.message
          })
        }
      })
    },
  }
}