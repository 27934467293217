import config from "../../../config"
import store from "../../store"
export default {
  namespaced: true,
  state: {

  },
  mutations: {
    
  },
  actions: {
    async cancelarFacturasManuales({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let cancelarPeticion = await fetch(`${config.BASE_URL}/adminFacturasAdmin/cancelFacturasRango`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let cancelar = await cancelarPeticion.json()
          cancelar.msj = cancelar.message
          return resolve(cancelar)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo cancelarFacturasManuales: ' + error.message
          })
        }
      })
    }
  }
}