import config from '../../config'
import store from '../store'
import { format_date, order_list_by } from '../util/utils'

export default {
  namespaced: true,
  state: {
    cajas_user: []
  },
  mutations: {
    set_cajas (state, payload) {
      order_list_by(payload, 'nombre')
      state.cajas_user = payload
    }
  },
  actions: {
    async get_data_print ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/giftCards/datosImprimir`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async baja_virtual ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/giftCards/bajaVirtual`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async alta_virtual ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/giftCards/altaVirtual`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_resumen_objetivos ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/giftCards/resumenObjetivos`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_cajas ({ state, commit }) {
      if (state.cajas_user.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/giftCards/cajasUser`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            commit('set_cajas', data.data)
          } else {
            commit('set_cajas', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
    
        } catch (error) {
          commit('set_cajas', [])
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      }
    },
    async get_destinatarios ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/giftCards/entidadesXSucAndTipo`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_tipos () {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/giftCards/tipos`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_caja ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/giftCards/caja`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_destinatario ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/giftCards/entidades`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_gift_cards ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/giftCards/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({
              codigo: payload.codigo,
              sucursal: payload.sucursal,
              estado: payload.estado,
              tipo: payload.tipo,
              entidad: payload.entidad_id,
              periodo: payload.periodo, // al periodo ya lo obtengo en el formato requerido
              fecha_desde: format_date(payload.fecha[0], 'DD/MM/YYYY'),
              fecha_hasta: format_date(payload.fecha[1], 'DD/MM/YYYY'),
            })
          })
    
          const data = await res.json()
    
          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_tipos_entidad () {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/giftCards/tiposEntidad`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_estados () {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/giftCards/estados`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
  },
}