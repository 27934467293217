var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"1000","fullscreen":_vm.fullscreen,"scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.titulo)+" "),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","title":_vm.fullscreen ? 'Minimizar' : 'Maximizar'},on:{"click":function($event){_vm.fullscreen = !_vm.fullscreen}}},[_c('v-icon',[_vm._v(" fas fa-window-"+_vm._s(_vm.fullscreen ? 'restore' : 'maximize')+" ")])],1),_c('v-btn',{attrs:{"icon":"","title":"Cerrar"},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("fas fa-times")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0",style:(_vm.$vuetify.breakpoint.smAndDown ? 'height: 500px' : 'height: 1000px')},[_c('vue-pdf-app',{attrs:{"pdf":_vm.pdf,"file-name":_vm.nombre,"config":{
          toolbar: {
            toolbarViewerRight: {
              presentationMode: false,
              openFile: false,
              print: _vm.print,
              download: true,
              viewBookmark: false
            }
          }
        }}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }