import config from "../../../config"
import store from "../../store"
import { objectToQuery } from '../../util/utils'

export default {
  namespaced: true,
  state: {

  },
  mutations: {
    
  },
  actions: {
    async initForm({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let initPeticion = await fetch(`${config.BASE_URL}/adminAcreditaciones/initForm`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let init = await initPeticion.json()
          init.msj = init.message
          return resolve(init)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo initForm: ' + error.message
          })
        }
      })
    },
    async getAcreditaciones({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let acreditaPeticion = await fetch(`${config.BASE_URL}/adminAcreditaciones/getAcreditacionesCliente?${objectToQuery(datos)}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let acredita = await acreditaPeticion.json()
          acredita.msj = acredita.message
          return resolve(acredita)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getAcreditaciones: ' + error.message
          })
        }
      })
    },
    async getImputacionesCliente({},datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let acreditaPeticion = await fetch(`${config.BASE_URL}/adminAcreditaciones/getImputacionesCliente?cliente_codigo=${datos.cliente_codigo}&detalle_numero=${datos.detalle_numero}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let acredita = await acreditaPeticion.json()
          acredita.msj = acredita.message
          return resolve(acredita)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getImputacionesCliente: ' + error.message
          })
        }
      })
    },
    async initFormABMAcreditaciones({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let initPeticion = await fetch(`${config.BASE_URL}/adminAcreditaciones/initFormABMAcreditaciones`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let init = await initPeticion.json()
          init.msj = init.message
          return resolve(init)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo initFormABMAcreditaciones: ' + error.message
          })
        }
      })
    },
    async getDetsAcreditaciones({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let detallesPeticion = await fetch(`${config.BASE_URL}/adminAcreditaciones/getDetsAcreditaciones?cliente_codigo=${datos.cliente_codigo}&ejecutivo_codigo=${datos.ejecutivo_codigo}&estado=${datos.estado}&fecha_desde=${datos.fecha_desde}&fecha_hasta=${datos.fecha_hasta}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let detalles = await detallesPeticion.json()
          detalles.msj = detalles.message
          return resolve(detalles)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getDetsAcreditaciones: ' + error.message
          })
        }
      })
    },
    async getOrigenesXCliente({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let origenesPeticion = await fetch(`${config.BASE_URL}/adminAcreditaciones/getOrigenesXCliente?cliente_codigo=${datos.cliente_codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let origenes = await origenesPeticion.json()
          origenes.msj = origenes.message
          return resolve(origenes)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getOrigenesXCliente: ' + error.message
          })
        }
      })
    },
    async getInteresCobro({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let interesPeticion = await fetch(`${config.BASE_URL}/adminAcreditaciones/getInteresCobro?tarjeta_codigo=${datos.tarjeta_codigo}&origen_codigo=${datos.origen_codigo}&cuotas=${datos.cuotas}&empresa_codigo=${datos.empresa_codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let interes = await interesPeticion.json()
          interes.msj = interes.message
          return resolve(interes)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getInteresCobro: ' + error.message
          })
        }
      })
    },
    async grabarAcreditacion({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let grabarPeticion = await fetch(`${config.BASE_URL}/adminAcreditaciones/grabarAcreditacion`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let grabar = await grabarPeticion.json()
          grabar.msj = grabar.message
          return resolve(grabar)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo grabarAcreditacion: ' + error.message
          })
        }
      })
    },
    async puedeEditarAcreditacion({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let puedePeticion = await fetch(`${config.BASE_URL}/adminAcreditaciones/puedeEditarAcreditacion?numero=${datos.numero}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let puede = await puedePeticion.json()
          puede.msj = puede.message
          return resolve(puede)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo puedeEditarAcreditacion: ' + error.message
          })
        }
      })
    },
    async editAcreditacion({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let editPeticion = await fetch(`${config.BASE_URL}/adminAcreditaciones/editAcreditacion`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let edit = await editPeticion.json()
          edit.msj = edit.message
          return resolve(edit)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo editAcreditacion: ' + error.message
          })
        }
      })
    },
    async anularAcreditacion({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let anularPeticion = await fetch(`${config.BASE_URL}/adminAcreditaciones/anularAcreditacion`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let anular = await anularPeticion.json()
          anular.msj = anular.message
          return resolve(anular)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo anularAcreditacion: ' + error.message
          })
        }
      })
    },
    async getEntidadesDepositos({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let entidadesPeticion = await fetch(`${config.BASE_URL}/adminAcreditaciones/getEntidadesDepositos`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let entidades = await entidadesPeticion.json()
          entidades.msj = entidades.message
          return resolve(entidades)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getEntidadesDepositos: ' + error.message
          })
        }
      })
    },
    async getAcreditacionData({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let dataPeticion = await fetch(`${config.BASE_URL}/adminAcreditaciones/getAcreditacionData?numero=${datos.numero}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let data = await dataPeticion.json()
          data.msj = data.message
          return resolve(data)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getAcreditacionData: ' + error.message
          })
        }
      })
    },
    async acreditacion ({}, numero) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminAcreditaciones/acreditacion?numero=${numero}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    }
  }
}