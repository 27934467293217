import config from '../../config'
import store from '../store'
import { order_list_by } from '../util/utils'

export default {
  namespaced: true,
  state: {
    tipos_productos: []
  },
  mutations: {
    set_tipos_productos (state, payload) {
      order_list_by(payload, 'nombre')
      state.tipos_productos = payload
    }
  },
  actions: {
    async anular_liquidacion ({}, num_liqui) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/grupopy/eliminarLiquidacion`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ num_liqui: num_liqui})
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_cantidad_liqui ({}, periodo) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/grupopy/cantidadLiqui?periodo=${periodo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async importar_base ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/grupopy/importarBase`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_datos_imprimir ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/grupopy/imprimir`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_liquidaciones_headers () {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/grupopy/liquidacionesHeaders`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async importar_archivos ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/grupopy/importarArchivos`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_tipos_productos ({ state, commit }) {
      if (state.tipos_productos.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/grupopy/tiposProductos`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            commit('set_tipos_productos', data.data)
          } else {
            commit('set_tipos_productos', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
    
        } catch (error) {
          commit('set_tipos_productos', [])
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      }
    },
    async get_detalle_liquidacion ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/grupopy/detalleLiquidacion?num_liqui=${payload.num_liqui}&ivr=${payload.ivr}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_liquidaciones ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/grupopy/getLiquidaciones`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(filtro)
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_liquidacion_por_periodo ({}, periodo) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/grupopy/liquidacionXPeriodo?periodo=${periodo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_ultima_liquidacion () {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/grupopy/ultimaLiquidacion`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    }
  }
}