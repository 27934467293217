import config from '../../../config'
import store from '../../store'

export default {
  namespaced: true,
  state: {

  },
  mutations: {
    
  },
  actions: {
    async initConciliacionBanco({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let initPeticion = await fetch(`${config.BASE_URL}/conciliaBancos/initConciliacionBanco`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let init = await initPeticion.json()
          init.msj = init.message
          return resolve(init)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo initConciliacionBanco: ' + error.message
          })
        }
      })
    },
    async getBancosXEmpresa({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let bancosPeticion = await fetch(`${config.BASE_URL}/conciliaBancos/getBancosXEmpresa?empresa_codigo=${datos.empresa_codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let bancos = await bancosPeticion.json()
          bancos.msj = bancos.message
          return resolve(bancos)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getBancosXEmpresa: ' + error.message
          })
        }
      })
    },
    async getCuentasBanco({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let cuentasPeticion = await fetch(`${config.BASE_URL}/conciliaTarjetas/getCuentasBanco?banco_codigo=${datos.banco_codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let cuentas = await cuentasPeticion.json()
          cuentas.msj = cuentas.message
          return resolve(cuentas)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getCuentasBanco: ' + error.message
          })
        }
      })
    },
    async buscarExtractos({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let extractosPeticion = await fetch(`${config.BASE_URL}/conciliaBancos/getExtractosBancarios`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let extractos = await extractosPeticion.json()
          extractos.msj = extractos.message
          return resolve(extractos)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo buscarExtractos: ' + error.message
          })
        }
      })
    },
    async getInfoMovBancario({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let movPeticion = await fetch(`${config.BASE_URL}/conciliaBancos/getInfoMovBancario?extracto_id=${datos.extracto_id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let movimiento = await movPeticion.json()
          movimiento.msj = movimiento.message
          return resolve(movimiento)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getInfoMovBancario: ' + error.message
          })
        }
      })
    },
    async buscarMovimientos({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let movPeticion = await fetch(`${config.BASE_URL}/conciliaBancos/getMovimBancarios?movimiento_codigo=${datos.movimiento_codigo}&empresa_codigo=${datos.empresa_codigo}&banco_codigo=${datos.banco_codigo}&cuenta_codigo=${datos.cuenta_codigo}&tipo_movimiento=${datos.tipo_movimiento}&estado=${datos.estado}&fecha_desde=${datos.fecha_desde}&fecha_hasta=${datos.fecha_hasta}&importe_desde=${datos.importe_desde}&importe_hasta=${datos.importe_hasta}&nro_comprobante=${datos.nro_comprobante}&sucursal_codigo=${datos.sucursal_codigo}&origen_movimiento=${datos.origen_movimiento}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let movimiento = await movPeticion.json()
          movimiento.msj = movimiento.message
          return resolve(movimiento)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo buscarMovimientos: ' + error.message
          })
        }
      })
    },
    async conciliarManual({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let conciliarPeticion = await fetch(`${config.BASE_URL}/conciliaBancos/conciliarManual`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let conciliar = await conciliarPeticion.json()
          conciliar.msj = conciliar.message
          return resolve(conciliar)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo conciliarManual: ' + error.message
          })
        }
      })
    },
    async desconciliar({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let desconciliarPeticion = await fetch(`${config.BASE_URL}/conciliaBancos/desconciliar`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let desconciliar = await desconciliarPeticion.json()
          desconciliar.msj = desconciliar.message
          return resolve(desconciliar)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo desconciliar Extracto Bancario: ' + error.message
          })
        }
      })
    },
    async conciliarOK({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let conciliarPeticion = await fetch(`${config.BASE_URL}/conciliaBancos/conciliarOK`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let conciliar = await conciliarPeticion.json()
          conciliar.msj = conciliar.message
          return resolve(conciliar)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo conciliarOK Extracto Bancario: ' + error.message
          })
        }
      })
    },
    async conciliarObs({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let conciliarPeticion = await fetch(`${config.BASE_URL}/conciliaBancos/conciliarObs`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let conciliar = await conciliarPeticion.json()
          conciliar.msj = conciliar.message
          return resolve(conciliar)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo conciliarObs Extracto Bancario: ' + error.message
          })
        }
      })
    },
    async getDatosExtracto({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let datosPeticion = await fetch(`${config.BASE_URL}/conciliaBancos/getDatosExtracto?extracto_id=${datos.extracto_id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let data = await datosPeticion.json()
          data.msj = data.message
          return resolve(data)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getDatosExtracto: ' + error.message
          })
        }
      })
    },
    async guardarObservacion({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let savePeticion = await fetch(`${config.BASE_URL}/conciliaBancos/guardarObservacion`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let save = await savePeticion.json()
          save.msj = save.message
          return resolve(save)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo guardarObservacion: ' + error.message
          })
        }
      })
    },
    async get_facturas({}, payload){
      return await new Promise(async (resolve, reject) => {
        try{
          let datosPeticion = await fetch(`${config.BASE_URL}/conciliaBancos/getFacturas?id_factura=${payload.id_factura}&empresa_codigo=${payload.empresa_codigo}&proveedor_codigo=${payload.proveedor_codigo}&fecha_rango_desde=${payload.fecha_rango[0]}&fecha_rango_hasta=${payload.fecha_rango[1]}&estado=${payload.estado}&estado_control=${payload.estado_control}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let data = await datosPeticion.json()
          return resolve(data)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo get_facturas: ' + error.message
          })
        }
      })
    },
    async conciliarCompras({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let conciliarPeticion = await fetch(`${config.BASE_URL}/conciliaBancos/conciliarCompras`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let save = await conciliarPeticion.json()
          
          return resolve(save)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo conciliarCompras: ' + error.message
          })
        }
      })
    },
  }
}