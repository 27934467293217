import store from "../store"
import config from "../../config"

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async initFormParametros({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let initPeticion = await fetch(`${config.BASE_URL}/AdminParametros/initFormParametros`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let init = await initPeticion.json()
          init.msj = init.message
          return resolve(init)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo initFormParametros: ' + error.message
          })
        }
      })
    },
    async getOperacionesParametros({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let paramPeticion = await fetch(`${config.BASE_URL}/AdminParametros/getOperacionesParametros?operacion_codigo=${datos.operacion_codigo}&estado_codigo=${datos.estado_codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let param = await paramPeticion.json()
          param.msj = param.message
          return resolve(param)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getOperacionesParametros: ' + error.message
          })
        }
      })
    },
    async updateParametro({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let updatePeticion = await fetch(`${config.BASE_URL}/AdminParametros/updateParametro`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let update = await updatePeticion.json()
          update.msj = update.message
          return resolve(update)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo updateParametro: ' + error.message
          })
        }
      })
    },
    async newParametro({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let nuevoPeticion = await fetch(`${config.BASE_URL}/AdminParametros/newParametro`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let nuevo = await nuevoPeticion.json()
          nuevo.msj = nuevo.message
          return resolve(nuevo)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo newParametro: ' + error.message
          })
        }
      })
    }
  }
}