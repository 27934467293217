import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-free/css/all.css'
import VueMask from 'v-mask'
import JsonExcel from 'vue-json-excel'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import device from 'vue-device-detector'
import VueZoomer from 'vue-zoomer'
import VueTheMask from 'vue-the-mask'
import VuetifyMask from "vuetify-mask"
import VueApexCharts from 'vue-apexcharts'
import 'vue-loaders/dist/vue-loaders.css'
import VueLoaders from 'vue-loaders'
import VuetifyMasked from 'vuetify-masked'

const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
}

Vue.use(VuetifyMasked)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.component('downloadExcel', JsonExcel)
Vue.use(VueTheMask)
Vue.use(VueSweetalert2, options)
Vue.use(VueMask)
Vue.use(device)
Vue.use(VueZoomer)
Vue.use(VuetifyMask)
Vue.use(VueLoaders)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
