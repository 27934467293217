import config from '../../../config'
import store from '../../store'
import { order_list_by } from '../../util/utils'

export default {
  namespaced: true,
  state: {
    rubros: []
  },
  mutations: {
    set_rubros (state, payload) {
      state.rubros = payload
      order_list_by(state.rubros, 'nombre')
      localStorage.setItem('rubros', JSON.stringify(payload))
    },
    add_rubro(state, payload){
      state.rubros.push(payload);
    }
  },
  actions: {
    // payload = codigo del rubro
    async inhabilitar_rubro ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/rubros/inhabilitarRubro`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ codigo: payload })
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    // payload = codigo del rubro
    async habilitar_rubro ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/rubros/habilitarRubro`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ codigo: payload })
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    // payload = codigo, nombre, familia_codigo, ind_rot_min e ind_rot_max
    async editar_rubro ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/rubros/editarRubro`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    // payload = nombre, familia_codigo, ind_rot_min e ind_rot_max
    async nuevo_rubro ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/rubros/crearRubro`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    // payload = filtro
    async get_rubros ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/rubros/getRubros`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_familias () {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/rubros/getFamilias`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    // obtiene los rubros habilitados y los guarda en el localStorage para utilizarlos en otros formularios
    async get_rubros_habilitados ({ dispatch, commit, state }) {
      if (state.rubros.length == 0) {
        await dispatch('get_rubros', { inhabilitado: 0})
          .then((res) => {
            commit('set_rubros', res.data)
          })
          .catch((error) => {
            commit('set_rubros', [])
            store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
      }
    },
    add_rubro({commit, state}, payload){
      commit('add_rubro', payload);
      commit('set_rubros', state.rubros);
    }
  }
}