import config from '../../config'
import store from '../store'

export default {
  namespaced: true,
  state: {
    empresas: [],
    areas: [],
    puestos: [],
    tipos_doc: []
  },
  mutations: {
    set_empresas (state, payload) {
      state.empresas = payload
    },
    set_areas (state, payload) {
      state.areas = payload
    },
    set_puestos (state, payload) {
      state.puestos = payload
    },
    set_tipos_doc (state, payload) {
      state.tipos_doc = payload
    }
  },
  actions: {
    async get_tipos_doc ({ commit, state }) {
      if (state.tipos_doc.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/serv/tiposDoc`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_tipos_doc', data.data)
          } else {
            commit('set_tipos_doc', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
  
        } catch (error) {
          commit('set_tipos_doc', [])
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      }
    },
    async get_puestos ({ commit, state }) {
      if (state.puestos.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/serv/puestos`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_puestos', data.data)
          } else {
            commit('set_puestos', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
  
        } catch (error) {
          commit('set_puestos', [])
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      }
    },
    async get_areas ({ commit, state }) {
      if (state.areas.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/serv/areas`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_areas', data.data)
          } else {
            commit('set_areas', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
  
        } catch (error) {
          commit('set_areas', [])
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      }
    },
    async get_empresas ({ commit, state }) {
      if (state.empresas.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/serv/empresas`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_empresas', data.data)
          } else {
            commit('set_empresas', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
  
        } catch (error) {
          commit('set_empresas', [])
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      }
    },
    // payload = nombre y marca (codigo)
    async nuevoEditarTam ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/nuevo_editar_tam`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json();

          resolve(data);
        } catch (error) {
          let accion = payload.nuevo ? 'crear' : 'editar';
          resolve({
            resultado: 0,
            message: `Ocurrió un error al intentar ${accion} el tamaño: `+error.message
          });
        }
      })
    },
    // payload = filtro
    async get_tamanios ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/get_tamanios?nombre=${payload.nombre}&inhabilitado=${payload.inhabilitado}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
          })

          const data = await res.json();

          resolve(data);
        } catch (error) {
          resolve({
            message:  'Ocurrió un problema al obtener los tamaños: '+error.message,
            resultado: 0      
          });
        }
      })
    }
  }
}