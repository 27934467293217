import config from '../../../config'
import store from '../../store'

export default {
  namespaced: true,
  state:{

  },
  mutations:{

  },
  getters:{

  },
  actions: {
    async initContribucionFranquicias({commit}){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminFranquicias/initContribFranquicias`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();

          if (data.resultado == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    async buscarVentasFranquicias({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminFranquicias/getVentasFranquicias?fecha_desde=${payload.fecha_desde}&fecha_hasta=${payload.fecha_hasta}&sucursal_codigo=${payload.sucursal_codigo}&local_codigo=${payload.local_codigo}&rubro_codigo=${payload.rubro_codigo}&categoria_codigo=${payload.categoria_codigo}&articulo_codigo=${payload.articulo_codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();

          if (data.resultado == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
  }
}